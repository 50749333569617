<template>
	<div class="lp">
		<h1 class="lp__header"><img src="~@/assets/img/lp_rainyseason2022/title.png" alt="梅雨だる・梅雨の頭痛にさよなら！頭痛ーるで雨の日も快適に presented by 頭痛ーる"></h1>

		<!-- イントロ -->
		<div class="lp__intro">
			<p class="lp__intro--text">梅雨シーズンは、連日の雨やジメジメした雰囲気で「なんとなく気分が下がる」という方も多いかもしれませんね。</p>
			<p class="lp__intro--text">それだけでなく、<em>気圧の影響</em>で様々な体調不良が起こりやすい時期でもあります。</p>
			<div class="lp__intro--img"><img src="~@/assets/img/lp_rainyseason2022/intro_img.png" alt="気象病"></div>
			<p class="lp__intro--text">天気の崩れや環境の変化に自分の体がついていけず、自分の弱いところが出てきてしまう病を<em>「気象病」</em>といいます。</p>
			<p class="lp__intro--text">低気圧の日が多くなる梅雨は、気象病に特に注意が必要な時期。<br>ですが、気圧予報アプリ<em>頭痛ーる</em>を使えば、気象病を予防したり事前に対策を取ったりできるので、ぐっと過ごしやすくなるんです。</p>
		</div>
		<!-- /イントロ -->

		<!-- 目次 -->
		<nav class="lp__link">
			<h2 class="lp__link--title"><img src="~@/assets/img/lp_rainyseason2022/rainyseason_title.png" alt="頭痛ーるで梅雨を快適に！"></h2>
			<ul class="lp__link--list">
				<li><a href="javascript:void(0)" @click="onScroll('js_check')" class="gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク1" data-gtm-event-act-label="trackingLpRainyseason2022Btn01"><img src="~@/assets/img/lp_rainyseason2022/linklist_01.png" alt="1分で診断！気象病セルフチェック"></a></li>
				<li><a href="javascript:void(0)" @click="onScroll('js_meteoropathy')" class="gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク2" data-gtm-event-act-label="trackingLpRainyseason2022Btn02"><img src="~@/assets/img/lp_rainyseason2022/linklist_02.png" alt="絵でわかるかんたん気象病対策"></a></li>
				<li><a href="javascript:void(0)" @click="onScroll('js_rainy')" class="gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク3" data-gtm-event-act-label="trackingLpRainyseason2022Btn03"><img src="~@/assets/img/lp_rainyseason2022/linklist_03.png" alt="2022年の最新梅雨予報"></a></li>
			</ul>
		</nav>
		<!-- /目次 -->

		<!-- チェック -->
		<section class="lp-contents" id="js_check">
			<div class="lp-contents__inner">
				<h2 class="lp-contents__title">あなたの体は気圧変化に弱い？<br>簡単チェックをやってみよう！</h2>
				<div class="lp-contents__check">
					<p class="lp-contents__check--text">「気象病セルフチェック」では、簡単な質問に答えるだけで、気象病である可能性をチェックできます。<br>ご自身の体調の傾向を知っておくことで、事前の対策が取りやすくなりますよ。</p>
					<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=selfcheckMeteoropathy` } })" class="lp-contents__check--link gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク4" data-gtm-event-act-label="trackingLpRainyseason2022Btn04"><img src="@/assets/img/lp_rainyseason2022/selfcheckmeteoropathy_bnr.gif" alt="医師監修 1分で診断！ 気象病セルフチェック"></a>
					<div class="lp-contents__check--profile">
						<img src="@/assets/img/lp_rainyseason2022/supervisor.png" alt="久手堅 司">
						<p>監修<br>せたがや内科・神経内科クリニック<br>院長・医学博士<br>久手堅 司（くでけん つかさ）</p>
					</div>
				</div>
			</div>
		</section>
		<!-- /チェック -->

		<!-- 気圧予報 -->
		<section class="lp-contents">
			<div class="lp-contents__inner">
				<h2 class="lp-contents__title">梅雨シーズンは頭痛ーるを<br>毎日チェックしよう！</h2>
				<div class="lp-contents__pressure">
					<p class="lp-contents__pressure--text">低気圧の日が多い梅雨シーズンは、ぜひ頭痛ーるを毎日チェックしてみて下さいね。</p>
					<img src="@/assets/img/lp_rainyseason2022/graph_img.png" alt="気圧予報" class="lp-contents__pressure--capture">
					<p class="lp-contents__pressure--text">低気圧になるタイミングが事前にわかると、「薬を飲む」「用事を先に済ませる」などの対策ができますよ。</p>
					<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="lp-contents__button--anchor gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク5Android" data-gtm-event-act-label="trackingLpRainyseason2022Btn05_android">気圧予報を見てみる</a>
					<a v-else @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=weatherGraph` } })" class="lp-contents__button--anchor gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク5iOS" data-gtm-event-act-label="trackingLpRainyseason2022Btn05_ios">気圧予報を見てみる</a>
				</div>
			</div>
			<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="lp-contents__button--download01 gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク6Android" data-gtm-event-act-label="trackingLpRainyseason2022Btn06_android"><img src="@/assets/img/lp_rainyseason2022/app_bnr01.png" alt="さて、今日の気圧は…？さっそくアプリでチェックじゃ！ 今すぐダウンロード！"></a>
			<a v-else @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="lp-contents__button--download01 gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク6iOS" data-gtm-event-act-label="trackingLpRainyseason2022Btn06_ios"><img src="@/assets/img/lp_rainyseason2022/app_bnr01.png" alt="さて、今日の気圧は…？さっそくアプリでチェックじゃ！ 今すぐダウンロード！"></a>
		</section>
		<!-- /気圧予報 -->

		<!-- 気象病対策 -->
		<section class="lp-contents" id="js_meteoropathy">
			<div class="lp-contents__inner">
				<h2 class="lp-contents__title">イラストで解説！<br>気象病の基礎知識と対策</h2>
				<div class="lp-contents__meteoropathy">
					<h3 class="lp-contents__meteoropathy--title">そもそも気象病とは？<br><span>▼気になるイラストをタップしよう▼</span></h3>
					<ul class="lp-contents__meteoropathy--list">
						<li class="lp-contents__meteoropathy--link01"><a href="javascript:void(0)" @click="clickModalLink(1, $event)" class="gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク7" data-gtm-event-act-label="trackingLpRainyseason2022Btn07"><img src="@/assets/img/lp_rainyseason2022/meteoropathy_info01.png" alt="気圧の変化でなぜ体調不良が起こるの？"></a></li>
						<li class="lp-contents__meteoropathy--link02"><a href="javascript:void(0)" @click="clickModalLink(2, $event)" class="gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク8" data-gtm-event-act-label="trackingLpRainyseason2022Btn08"><img src="@/assets/img/lp_rainyseason2022/meteoropathy_info02.png" alt="頭痛にはどんな種類があるの？"></a></li>
						<li class="lp-contents__meteoropathy--link03"><a href="javascript:void(0)" @click="clickModalLink(3, $event)" class="gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク9" data-gtm-event-act-label="trackingLpRainyseason2022Btn09"><img src="@/assets/img/lp_rainyseason2022/meteoropathy_info03.png" alt="いつがベスト？ 薬を飲むタイミング"></a></li>
					</ul>
					<h3 class="lp-contents__meteoropathy--title">梅雨のおうち時間に！<br><span>▼気になるイラストをタップしよう▼</span></h3>
					<ul class="lp-contents__meteoropathy--list">
						<li class="lp-contents__meteoropathy--link04"><a href="javascript:void(0)" @click="clickModalLink(4, $event)" class="gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク10" data-gtm-event-act-label="trackingLpRainyseason2022Btn10"><img src="@/assets/img/lp_rainyseason2022/meteoropathy_stretch01.png" alt="内耳の血管を促す！ くるっと耳ストレッチ"></a></li>
						<li class="lp-contents__meteoropathy--link05"><a href="javascript:void(0)" @click="clickModalLink(5, $event)" class="gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク11" data-gtm-event-act-label="trackingLpRainyseason2022Btn11"><img src="@/assets/img/lp_rainyseason2022/meteoropathy_stretch02.png" alt="肩こりリセット！ 前ならえ体操"></a></li>
						<li class="lp-contents__meteoropathy--link06"><a href="javascript:void(0)" @click="clickModalLink(6, $event)" class="gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク12" data-gtm-event-act-label="trackingLpRainyseason2022Btn12"><img src="@/assets/img/lp_rainyseason2022/meteoropathy_stretch03.png" alt="頭スッキリ&amp;集中力UP！ 12秒呼吸法 胸式呼吸・腹式呼吸"></a></li>
					</ul>
				</div>
			</div>
		</section>
		<!-- /気象病対策 -->

		<!-- 梅雨予報 -->
		<section class="lp-contents" id="js_rainy">
			<div class="lp-contents__inner">
				<h2 class="lp-contents__title">どのくらい続く？<br>2022年の梅雨予報</h2>
				<div class="lp-contents__rainy">
					<div class="lp-contents__rainy--capture"><img src="@/assets/img/lp_rainyseason2022/map_img.png" alt="梅雨予報"></div>
					<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=columnDetail`, _detailId: 801 } })" class="lp-contents__button--anchor gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク13" data-gtm-event-act-label="trackingLpRainyseason2022Btn13">梅雨予報を見てみる</a>
				</div>
			</div>
			<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="lp-contents__button--download02 gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク14Android" data-gtm-event-act-label="trackingLpRainyseason2022Btn14_android"><img src="@/assets/img/lp_rainyseason2022/app_bnr02.png" alt="雨の日ってやっぱり低気圧なのかニャ？一緒にアプリで見てみるニャ！今すぐダウンロード！"></a>
			<a v-else @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="lp-contents__button--download02 gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク14iOS" data-gtm-event-act-label="trackingLpRainyseason2022Btn14_ios"><img src="@/assets/img/lp_rainyseason2022/app_bnr02.png" alt="雨の日ってやっぱり低気圧なのかニャ？一緒にアプリで見てみるニャ！今すぐダウンロード！"></a>
		</section>
		<!-- /梅雨予報 -->

		<!-- スタンププレゼント -->
		<section class="lp-stamp">
			<h2 class="lp-stamp__title"><img src="@/assets/img/lp_rainyseason2022/stamp_title.png" alt="梅雨を楽しく!! 【スゴ得限定】スタンププレゼント"></h2>
			<ul class="lp-stamp__list">
				<li class="lp-stamp__item"><img src="@/assets/img/stamp/rainyseason202206_stamp01.png" alt="あめだニャー"></li>
				<li class="lp-stamp__item"><img src="@/assets/img/stamp/rainyseason202206_stamp02.png" alt="やまない雨はナイ"></li>
				<li class="lp-stamp__item"><img src="@/assets/img/stamp/rainyseason202206_stamp05.png" alt="忘れずに！"></li>
				<li class="lp-stamp__item"><img src="@/assets/img/stamp/rainyseason202206_stamp04.png" alt="ザパー"></li>
			</ul>
			<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=stamp`, _stampType: 'rainyseason', _backPage: 'top' } })" class="lp-stamp__button--anchor gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク15" data-gtm-event-act-label="trackingLpRainyseason2022Btn15">プレゼントをもらう</a>
		</section>
		<!-- /スタンププレゼント -->

		<!-- アウトロ -->
		<div class="lp-outro">
			<p>頭痛ーると梅雨を乗り切ろう！</p>
			<img src="@/assets/img/lp_rainyseason2022/outro_img.png" alt="">
			<a @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })" class="lp-outro__button--back gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク16" data-gtm-event-act-label="trackingLpRainyseason2022Btn16">TOPにもどる</a>
		</div>
		<!-- /アウトロ -->

		<!-- 下に吸着するアプリダウンロードボタン -->
		<aside class="sticky-bottom-appdownload">
			<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="sticky-bottom-appdownload--button gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク17Android" data-gtm-event-act-label="trackingLpRainyseason2022Btn17_android"><img src="~@/assets/img/sugotoku_app_info_btn03.png" alt="今すぐダウンロード！"></a>
			<a v-else href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="sticky-bottom-appdownload--button gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク17iOS" data-gtm-event-act-label="trackingLpRainyseason2022Btn17_ios"><img src="~@/assets/img/sugotoku_app_info_btn03.png" alt="今すぐダウンロード！"></a>
		</aside>
		<!-- /下に吸着するアプリダウンロードボタン -->

		<!-- モーダル -->
		<v-ons-modal :visible="isVisibleModal" animation="fade" animationOptions="{duration: 0.2, timing: 'ease-in'}" class="lp__modal">
			<aside class="lp__modal--inner">
				<div v-show="modalType === 1" class="lp__modal--body">
					<!-- slider -->
					<div class="meteoropathy__slider">
						<div class="meteoropathy__slider--container swiper-container js_swiper">
							<ul class="swiper-wrapper sprite-meteoropathy">
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_why img01"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_why img02"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_why img03"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_why img04"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_why img05"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_why img06"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_why img07"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_why img08"></i>
								</li>
								<li class="swiper-slide">
									<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=columnDetail`, _detailId: 722 } })" class="gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク18" data-gtm-event-act-label="trackingLpRainyseason2022Btn18"><i class="sprite-meteoropathy_why img09"></i></a>
								</li>
							</ul>
						</div>
						<div class="meteoropathy__slider--nav">
							<div class="swiper-button-prev js_swiper_prev"></div>
							<div class="swiper-button-next js_swiper_next"></div>
						</div>
						<div class="swiper-pagination-bullets js_swiper_pagination"></div>
					</div>
					<!-- /slider -->
				</div>
				<div v-show="modalType === 2" class="lp__modal--body">
					<!-- slider -->
					<div class="meteoropathy__slider">
						<div class="meteoropathy__slider--container swiper-container js_swiper">
							<ul class="swiper-wrapper sprite-meteoropathy">
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_type img01"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_type img02"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_type img03"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_type img04"></i>
								</li>
								<li class="swiper-slide">
									<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=headcheTypeContent` } })" class="gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク19" data-gtm-event-act-label="trackingLpRainyseason2022Btn19"><i class="sprite-meteoropathy_type img05"></i></a>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_type img06"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_type img07"></i>
								</li>
								<li class="swiper-slide">
									<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=columnDetail`, _detailId: 21 } })" class="gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク20" data-gtm-event-act-label="trackingLpRainyseason2022Btn20"><i class="sprite-meteoropathy_type img08"></i></a>
								</li>
								<li class="swiper-slide">
									<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク21Android" data-gtm-event-act-label="trackingLpRainyseason2022Btn21_android"><i class="sprite-meteoropathy_type img09"></i></a>
									<a v-else href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク21iOS" data-gtm-event-act-label="trackingLpRainyseason2022Btn21_ios"><i class="sprite-meteoropathy_type img09"></i></a>
								</li>
							</ul>
						</div>
						<div class="meteoropathy__slider--nav">
							<div class="swiper-button-prev js_swiper_prev"></div>
							<div class="swiper-button-next js_swiper_next"></div>
						</div>
						<div class="swiper-pagination-bullets js_swiper_pagination"></div>
					</div>
					<!-- /slider -->
				</div>
				<div v-show="modalType === 3" class="lp__modal--body">
					<!-- slider -->
					<div class="meteoropathy__slider">
						<div class="meteoropathy__slider--container swiper-container js_swiper">
							<ul class="swiper-wrapper sprite-meteoropathy">
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_timing img01"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_timing img02"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_timing img03"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_timing img04"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_timing img05"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_timing img06"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_timing img07"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_timing img08"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_timing img09"></i>
								</li>
								<li class="swiper-slide">
									<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=columnDetail`, _detailId: 78 } })" class="gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク22" data-gtm-event-act-label="trackingLpRainyseason2022Btn22"><i class="sprite-meteoropathy_timing img10"></i></a>
								</li>
							</ul>
						</div>
						<div class="meteoropathy__slider--nav">
							<div class="swiper-button-prev js_swiper_prev"></div>
							<div class="swiper-button-next js_swiper_next"></div>
						</div>
						<div class="swiper-pagination-bullets js_swiper_pagination"></div>
					</div>
					<!-- /slider -->
				</div>
				<div v-show="modalType === 4" class="lp__modal--body">
					<!-- slider -->
					<div class="meteoropathy__slider">
						<div class="meteoropathy__slider--container swiper-container js_swiper">
							<ul class="swiper-wrapper sprite-meteoropathy">
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_ear img01"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_ear img02"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_ear img03"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_ear img04"></i>
								</li>
								<li class="swiper-slide">
									<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=columnDetail`, _detailId: 722 } })" class="gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク23" data-gtm-event-act-label="trackingLpRainyseason2022Btn23"><i class="sprite-meteoropathy_ear img05"></i></a>
								</li>
								<li class="swiper-slide">
									<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク24Android" data-gtm-event-act-label="trackingLpRainyseason2022Btn24_android"><i class="sprite-meteoropathy_ear img06"></i></a>
									<a v-else href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク24iOS" data-gtm-event-act-label="trackingLpRainyseason2022Btn24_ios"><i class="sprite-meteoropathy_ear img06"></i></a>
								</li>
							</ul>
						</div>
						<div class="meteoropathy__slider--nav">
							<div class="swiper-button-prev js_swiper_prev"></div>
							<div class="swiper-button-next js_swiper_next"></div>
						</div>
						<div class="swiper-pagination-bullets js_swiper_pagination"></div>
					</div>
					<!-- /slider -->
				</div>
				<div v-show="modalType === 5" class="lp__modal--body">
					<!-- slider -->
					<div class="meteoropathy__slider">
						<div class="meteoropathy__slider--container swiper-container js_swiper">
							<ul class="swiper-wrapper sprite-meteoropathy">
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_fallin img01"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_fallin img02"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_fallin img03"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_fallin img04"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_fallin img05"></i>
								</li>
								<li class="swiper-slide">
									<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=columnDetail`, _detailId: 722 } })" class="gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク25" data-gtm-event-act-label="trackingLpRainyseason2022Btn25"><i class="sprite-meteoropathy_fallin img06"></i></a>
								</li>
								<li class="swiper-slide">
									<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク26Android" data-gtm-event-act-label="trackingLpRainyseason2022Btn26_android"><i class="sprite-meteoropathy_fallin img07"></i></a>
									<a v-else href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク26iOS" data-gtm-event-act-label="trackingLpRainyseason2022Btn26_ios"><i class="sprite-meteoropathy_fallin img07"></i></a>
								</li>
							</ul>
						</div>
						<div class="meteoropathy__slider--nav">
							<div class="swiper-button-prev js_swiper_prev"></div>
							<div class="swiper-button-next js_swiper_next"></div>
						</div>
						<div class="swiper-pagination-bullets js_swiper_pagination"></div>
					</div>
					<!-- /slider -->
				</div>
				<div v-show="modalType === 6" class="lp__modal--body">
					<!-- slider -->
					<div class="meteoropathy__slider">
						<div class="meteoropathy__slider--container swiper-container js_swiper">
							<ul class="swiper-wrapper sprite-meteoropathy">
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_breath img01"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_breath img02"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_breath img03"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_breath img04"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_breath img05"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_breath img06"></i>
								</li>
								<li class="swiper-slide">
									<i class="sprite-meteoropathy_breath img07"></i>
								</li>
								<li class="swiper-slide">
									<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=columnDetail`, _detailId: 725 } })" class="gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク27" data-gtm-event-act-label="trackingLpRainyseason2022Btn27"><i class="sprite-meteoropathy_breath img08"></i></a>
								</li>
								<li class="swiper-slide">
									<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク28Android" data-gtm-event-act-label="trackingLpRainyseason2022Btn28_android"><i class="sprite-meteoropathy_breath img09"></i></a>
									<a v-else href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="gtm-link_click_count01" data-gtm-event-cat-label="梅雨特集ページ2022リンク28iOS" data-gtm-event-act-label="trackingLpRainyseason2022Btn28_ios"><i class="sprite-meteoropathy_breath img09"></i></a>
								</li>
							</ul>
						</div>
						<div class="meteoropathy__slider--nav">
							<div class="swiper-button-prev js_swiper_prev"></div>
							<div class="swiper-button-next js_swiper_next"></div>
						</div>
						<div class="swiper-pagination-bullets js_swiper_pagination"></div>
					</div>
					<!-- /slider -->
				</div>
				<div class="lp__modal--button">
					<a @click="clickModalLink(0, $event)">
						<ons-icon icon="md-close" />表示を閉じる
					</a>
				</div>
			</aside>
		</v-ons-modal>
		<!-- /モーダル -->
	</div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// ライブラリ
import cmnConst from '@/assets/js/constant.js'
import Swiper from 'swiper'

export default {
	name: 'LpRainyseason2022',
	components: {
	},
	data () {
		return {
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID,
			backendUrl: cmnConst.BACKEND_URL,
			isVisibleModal: false,
			modalType: 0
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isAndroid'])
	},
	mounted () {
		this.onSwiper()
	},
	beforeDestroy () {
	},
	methods: {
		// ページスクロール用の関数
		onScroll (id) {
			const elem = document.getElementById(id)
			const options = {
				container: 'body',
				duration: 500,
				easing: 'ease-out',
				offset: 0,
				cancelable: false,
				onStart: false,
				onDone: false,
				onCancel: false,
				x: false,
				y: true
			}
			this.$scrollTo(elem, options)
		},

		// モーダルのリンククリックイベント
		clickModalLink (value, event) {
			event.preventDefault()
			this.isVisibleModal = value > 0
			if (this.isVisibleModal) this.modalType = value
		},

		// Swiperの処理
		onSwiper () {
			const swiperConstructor = () => {
				let elmSwiper = document.querySelectorAll('.js_swiper')
				let elmNext = document.querySelectorAll('.js_swiper_next')
				let elmPrev = document.querySelectorAll('.js_swiper_prev')
				let elmPager = document.querySelectorAll('.js_swiper_pagination')
				let initSwiper
				for (let i = 0; i < elmSwiper.length; i++) {
					elmSwiper[i].className += i
					elmNext[i].className += i
					elmPrev[i].className += i
					elmPager[i].className += i
					initSwiper = new Swiper('.js_swiper' + i, {
						slidesPerView: 1,
						spaceBetween: 10,
						observer: true,
						observeParents: true,
						navigation: {
							nextEl: '.js_swiper_next' + i,
							prevEl: '.js_swiper_prev' + i
						},
						pagination: {
							el: '.js_swiper_pagination' + i
						}
					})
				}
				return initSwiper
			}
			swiperConstructor()
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../assets/sass/variable";
@import "~swiper/css/swiper.min.css";

// NOTE: LPページ限定の変数（デザインシステム外のため他での利用禁止）
$text-em: #FF4D5F;
$lp-background: #F5F5F5;
$lp-contents-background: #B8E0F7;
$lp-contents-top-background: #1EBFBB;
$lp-contents-title: #21498D;
$lp-contents-btn: #0071DB;
$lp-contents-btn-shadow: #004AC8;
$lp-stamp-btn: #FF4D5F;
$lp-stamp-btn-shadow: #FF001A;
$spacing-12: 0.75rem;
$spacing-22: 1.375rem;
$spacing-24: 1.5rem;
$spacing-28: 1.75rem;
$spacing-34: 2.125rem;
$spacing-42: 2.625rem;
$spacing-48: 3rem;
$line-height-1375: 1.375;

.lp {
	margin: 0 auto;
	max-width: 640px;
	font-family: $lp-family-primary;
	font-size: $font-size-16;
	background-color: $lp-background;

	&__header {
		margin: 0 0 $spacing-10;
		padding: $spacing-10 $spacing-14 0;

		img {
			margin: 0 auto;
			display: block;
			width: 100%;
		}
	}

	&__intro {
		padding-bottom: $spacing-6;

		&--text {
			margin: 0 0 $spacing-24;
			padding: 0 $spacing-14;
			line-height: $line-height-1375;

			em {
				color: $text-em;
				font-family: $lp-family-tertiary;
				font-style: normal;
			}
		}

		&--img {
			padding: 0 $spacing-14 $spacing-18;

			img {
				margin: 0 auto;
				display: block;
				width: 100%;
			}
		}
	}

	&__link {
		padding: $spacing-48 0 $spacing-30;
		background: url(~@/assets/img/lp_rainyseason2022/contents_bg_top.png) top center / 100% auto no-repeat $lp-contents-background;

		&--title {
			margin: 0;
			padding: 0 $spacing-14;

			img {
				margin: 0 auto;
				display: block;
				width: 100%;
			}
		}

		&--list {
			margin: 0 auto;
			padding: $spacing-14 $spacing-14 0;
			display: flex;
			justify-content: space-between;
			align-items: center;

			li {
				width: 31.12%;

				img {
					margin: 0 auto;
					display: block;
					width: 100%;
				}
			}
		}
	}

	&-contents {
		overflow: hidden;
		background-color: $lp-contents-background;

		&__inner {
			margin: 0 auto $spacing-28;
			padding: $spacing-30 $spacing-20 $spacing-22;
			border-radius: 10px;
			width: calc(100% - 1.625rem);
			background: linear-gradient(to bottom, $lp-contents-top-background $spacing-10, $text-seconday $spacing-10 100%);
			box-sizing: border-box;
		}

		&__title {
			margin: 0 0 $spacing-12;
			padding: 0 0 $spacing-30;
			color: $lp-contents-title;
			font-family: $lp-family-tertiary;
			font-weight: normal;
			font-size: $font-size-20;
			text-align: center;
			background: url(~@/assets/img/lp_rainyseason2022/contentstitle_bg.png) bottom center / 68.8% auto no-repeat;

			@media (max-width: (460px)) {
				padding: 0 0 $spacing-20;
			}
			@media (max-width: (350px)) {
				font-size: $font-size-18;
			}
		}

		&__button {
			&--anchor {
				margin: 0 auto;
				padding: $spacing-10 0;
				border-radius: 6px;
				display: block;
				max-width: 295px;
				color: $text-seconday;
				font-family: $lp-family-secondary;
				font-style: normal;
				font-size: $font-size-18;
				text-align: center;
				text-decoration: none;
				background-color: $lp-contents-btn;
				box-shadow: 0 3px 0 0 $lp-contents-btn-shadow;
			}

			&--download01,
			&--download02 {
				margin: 0 auto $spacing-30;
				display: block;

				img {
					margin: 0 auto;
					display: block;
					width: 100%;
				}
			}

			&--download01 {
				width: calc(100% - #{$spacing-24});
			}

			&--download02 {
				width: calc(100% - #{$spacing-10});
			}

		}

		&__pressure {
			&--text {
				margin: 0 0 $spacing-14;
				line-height: $line-height-1375;
			}

			&--capture {
				margin: 0 auto $spacing-12;
				display: block;
				width: 100%;
			}
		}

		&__check {
			&--text {
				margin: 0 0 $spacing-16;
				line-height: $line-height-1375;
			}

			&--link {
				padding: 0;
				display: block;

				img {
					margin: 0 auto $spacing-16;
					display: block;
					width: 100%;
				}
			}

			&--profile {
				margin: 0 auto;
				padding: $spacing-10 $spacing-12;
				border-radius: 10px;
				background-color: $lp-background;
				display: flex;
				align-items: center;
				justify-content: space-between;
				box-sizing: border-box;

				img {
					display: block;
					width: 72px;
				}

				p {
					margin: 0;
					font-size: $font-size-12;
					width: calc(100% - 84px);

				}
			}
		}

		&__meteoropathy {
			&--title {
				margin: 0 0 $spacing-18;
				font-family: $lp-family-primary;
				font-weight: normal;
				font-size: $font-size-16;
				text-align: center;
				line-height: 1.375;
				@media (max-width: (350px)) {
					font-size: $font-size-14;
				}

				span {
					color: $text-em;
					font-family: $lp-family-tertiary;
				}

				&:nth-of-type(n + 2) {
					margin-top: $spacing-14;
				}
			}

			&--list {
				margin: 0;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: space-between;

				li {
					width: 32%;

					img {
						display: block;
						width: 100%;
					}
				}
			}
		}

		&__rainy {
			&--capture {
				margin: 0 0 $spacing-10;

				img {
					display: block;
					width: 100%;
				}
			}
		}
	}

	&-stamp {
		padding: $spacing-42 0 $spacing-34;
		background: url(~@/assets/img/lp_rainyseason2022/contents_bg_bottom.png) top center / 100% auto no-repeat;

		&__title {
			margin: 0 0 $spacing-20;

			img {
				margin: 0 auto;
				display: block;
				width: 92.5%;
			}
		}

		&__list {
			margin: 0 auto 0;
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			max-width: 345px;
			width: auto;

			@media (max-width: (360px)) {
				max-width: 300px;
			}
		}

		&__item {
			margin: 0 0 $spacing-20;
			border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 78px;
			height: 78px;
			background-color: $background-primary;
			overflow: hidden;
			@media (max-width: (360px)) {
				width: 68px;
				height: 68px;
			}

			img {
				display: block;
				width: 100%;
			}
		}

		&__button {
			&--anchor {
				margin: 0 auto;
				padding: $spacing-10 0;
				border-radius: 6px;
				display: block;
				max-width: 295px;
				color: $text-seconday;
				font-family: $lp-family-secondary;
				font-style: normal;
				font-size: $font-size-18;
				text-align: center;
				background-color: $lp-stamp-btn;
				box-shadow: 0 3px 0 0 $lp-stamp-btn-shadow;
			}
		}
	}

	&-outro {
		p {
			margin: 0 0 $spacing-14;
			text-align: center;
			font-family: $lp-family-secondary;
			font-weight: normal;
		}

		img {
			margin: 0 auto $spacing-20;
			display: block;
			width: 66.66%;
		}

		&__button {
			margin: $spacing-34 0 0 0;

			&--back {
				margin: 0 auto;
				padding: 0.5rem 0;
				border-radius: 100px;
				position: relative;
				display: block;
				max-width: 295px;
				color: $text-seconday;
				font-family: $lp-family-secondary;
				font-weight: normal;
				font-size: $font-size-24;
				background-color: $primary;
				text-align: center;
				line-height: 1.4;
			}
		}
	}

	&__modal {
		color: $text-primary;
		position: fixed;
		height: 100vh;

		/deep/ .modal__content {
			padding-top: 10vh;
			vertical-align: top;
		}

		&--inner {
			margin: 0 auto;
			width: 347px;
			background-color: $background-primary;

			@media (max-width: (374px)) {
				width: 310px;
			}
		}

		&--body {
			position: relative;
		}

		&--button {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 35px;
			background: $background-secondary;
			box-sizing: border-box;

			& > a {
				color: $text-primary;
				font-size: $font-size-12;

				ons-icon {
					font-size: $font-size-16;
					vertical-align: middle;
					margin-right: $spacing-10;
				}
			}
		}
	}
}

.sticky-bottom-appdownload {
	margin-top: $spacing-20;
	padding: $spacing-8 $spacing-14;
	box-shadow: 0px -2px 2px rgba(0,0,0,0.1);
}

// swiper
.swiper-container {
	margin: 0;
	@media (max-width: (374px)) {
		height: 310px;
	}
}
.swiper-wrapper {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.meteoropathy__slider--nav {
	.swiper-button-prev,
	.swiper-button-next {
		margin-top: -40px;
		width: 40px;
		height: 40px;

		&:before,
		&:after {
			display: none;
		}

		&.swiper-button-disabled {
			opacity: 0;
		}
	}
	.swiper-button-prev {
		left: 10px;
		background: url(~@/assets/img/lp_rainyseason2022/slider_prev.png) center center / 100% auto no-repeat;
	}
	.swiper-button-next {
		right: 10px;
		background: url(~@/assets/img/lp_rainyseason2022/slider_next.png) center center / 100% auto no-repeat;
	}
}

.swiper-pagination-bullets {
	padding: $spacing-14 0;
	display: flex;
	align-items: center;
	justify-content: center;
	/deep/ .swiper-pagination-bullet {
		margin: 0 $spacing-10;
		width: 5px;
		height: 5px;
		background: $button-secondary;
		opacity: 1;
	}
	/deep/ .swiper-pagination-bullet-active {
		background: $text-primary;
	}
}

.sprite-meteoropathy {
	&_why {
		background-image: url(~@/assets/img/lp_rainyseason2022/sprite_meteoropathy_why.png);
		background-size: (714px / 2) (6426px / 2);
	}
	&_type {
		background-image: url(~@/assets/img/lp_rainyseason2022/sprite_meteoropathy_type.png);
		background-size: (714px / 2) (6426px / 2);
	}
	&_timing {
		background-image: url(~@/assets/img/lp_rainyseason2022/sprite_meteoropathy_timing.png);
		background-size: (714px / 2) (7140px / 2);
	}
	&_ear {
		background-image: url(~@/assets/img/lp_rainyseason2022/sprite_meteoropathy_ear.png);
		background-size: (714px / 2) (4284px / 2);
	}
	&_fallin {
		background-image: url(~@/assets/img/lp_rainyseason2022/sprite_meteoropathy_fallin.png);
		background-size: (714px / 2) (4998px / 2);
	}
	&_breath {
		background-image: url(~@/assets/img/lp_rainyseason2022/sprite_meteoropathy_breath.png);
		background-size: (714px / 2) (6426px / 2);
	}
	i {
		background-repeat: no-repeat;
		display: block;
		width: 347px;
		height: 347px;
		@media (max-width: (374px)) {
			transform: scale(.89336);
			transform-origin: 0 0;
		}
	}

	.img01 {
		background-position: (-10px / 2) (-10px / 2);
	}

	.img02 {
		background-position: (-10px / 2) (-724px / 2);
	}

	.img03 {
		background-position: (-10px / 2) (-1438px / 2);
	}

	.img04 {
		background-position: (-10px / 2) (-2152px / 2);
	}

	.img05 {
		background-position: (-10px / 2) (-2866px / 2);
	}

	.img06 {
		background-position: (-10px / 2) (-3580px / 2);
	}

	.img07 {
		background-position: (-10px / 2) (-4294px / 2);
	}

	.img08 {
		background-position: (-10px / 2) (-5008px / 2);
	}

	.img09 {
		background-position: (-10px / 2) (-5722px / 2);
	}

	.img10 {
		background-position: (-10px / 2) (-6436px / 2);
	}

}
</style>
